import { _js } from '@ifixit/localize';
import { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import styled from 'styled-components';
import { color } from '@ifixit/primitives';
import initializeComponent from 'Shared/react-initialize';
import TocItem from './item';
import { tableOfContentsGenerated } from 'Guide/WikiPages/wiki_view';

const Container = styled.div`
   display: flex;
   flex-direction: column;
   background: white;
   box-shadow:
      0px 1px 4px rgba(16, 22, 26, 0.1),
      0px 1px 2px rgba(16, 22, 26, 0.1);
`;

const Header = styled.h3`
   margin: 0;
   padding: 16px 24px;
   font-size: 12px;
   text-transform: uppercase;
   color: ${color.gray[700]};
   border-bottom: 1px solid ${color.gray[200]};
`;

const SectionContainer = styled.div`
   padding: 24px 0 10px 24px;
`;

const ButtonContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 36px;
   cursor: pointer;
`;

// Generate TOC elements based on the Desktop TOC sections.
const generateTocSections = desktopToc =>
   new Promise(resolve => {
      const toc = desktopToc.getElement('ul');
      const tocSections = [];

      toc.getChildren().each((section, i) => {
         const elements = section.getElements('a');
         elements.each((el, j) => {
            const title = el.get('text');
            const item = {
               element: el,
               title,
               href: el.href,
               subSections: [],
            };

            if (j === 0) {
               tocSections.push(item);
            } else {
               tocSections[i].subSections.push(item);
            }
         });
      });

      resolve(tocSections);
   });

function Toc({ tocSections }) {
   const [expanded, setExpanded] = useState(false);
   const toggleExpanded = useCallback(() => setExpanded(e => !e));

   let sections = tocSections.map(sectionData => (
      <TocItem key={`section-${sectionData.title}`} sectionData={sectionData} />
   ));

   return tocSections.length > 0 ? (
      <Container>
         <Header>{_js('Table of Contents')}</Header>
         {expanded ? <SectionContainer>{sections}</SectionContainer> : null}
         <ButtonContainer className="mobile-wiki-toc-expand" onClick={toggleExpanded}>
            <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
         </ButtonContainer>
      </Container>
   ) : null;
}

tableOfContentsGenerated.then(generateTocSections).then(tocSections => {
   initializeComponent('Toc', Toc, { tocSections });
});
