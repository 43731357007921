import { _js } from '@ifixit/localize';
import { Modal } from 'Shared/modal';
import copyToClipboard from 'Shared/copy_to_clipboard';

onDomReady(() => {
   when($('copy-wikiid-link'), () => {
      $('copy-wikiid-link').addEvent('click', ev => {
         ev.stop();
         let copyText = ev.target.childNodes[2].nodeValue;
         ev.target.childNodes[2].nodeValue = _js('Copied!');

         copyToClipboard(App.wikiid);

         setTimeout(() => {
            ev.target.childNodes[2].nodeValue = copyText;
         }, 5000);
      });
   });

   when($E('#edit-nav-link, #translate-nav-link'), link => {
      link = link.get('href');
      $$('.editSectionLink').each(input => {
         input.addEvent('click', ev => {
            ev.stop();
            let offset = input.get('data-offset');
            window.location = link + '?offset=' + offset;
         });
      });
   });

   const initPagination = function (container, handler) {
      let wrapper = container.parentElement;
      let pageButtons = wrapper.getChildren('.paginate');
      pageButtons.addEvent('click', ev => {
         wrapper.getChildren('.progress').show();
         wrapper.getChildren('.paginationInfo').hide();

         ev.stop();

         let offset = Number.parseInt(container.get('data-offset'), 10),
            limit = Number.parseInt(container.get('data-limit'), 10),
            wikiid = container.get('data-wikiid'),
            total = Number.parseInt(container.get('data-total'), 10);

         if (ev.target.hasClass('previous')) {
            offset -= limit;
         } else {
            offset += limit;
         }

         container.set('data-offset', offset);
         new Request.AjaxIO(handler, {
            onSuccess: function (data) {
               wrapper.getChildren('.progress').hide();
               wrapper.getChildren('.paginationInfo').show();
               wrapper
                  .getElement('.currentPage')
                  .set('text', Math.ceil(data.offset / data.limit) + 1);
               container.set('html', data.html);

               if (data.offset + data.limit >= total) {
                  wrapper.getChildren('.paginate.next').hide();
               } else {
                  wrapper.getChildren('.paginate.next').show();
               }

               if (data.offset <= 0) {
                  wrapper.getChildren('.paginate.previous').hide();
               } else {
                  wrapper.getChildren('.paginate.previous').show();
               }
            },
         }).send(wikiid, limit, offset);
      });
   };

   when($('diagramItemList'), container => {
      initPagination(container, 'getDiagramItems');
   });

   when($('suppliers'), el => {
      if ($$('.wikiInfo').length === 0) {
         el.addClass('fullWidth');
      }
   });

   $$('.topicHeaderTextToggle').each(el => {
      el.addEvent('click', ev => {
         ev.stop();
         $$('.topicHeaderText').toggleClass('hidden');
      });
   });

   $$('.modalImgView').each(el => {
      el.addEvent('click', ev => {
         ev.stop();
         Modal.openModalImg(
            el.get('data-modalimgurl'),
            el.get('data-modalImgWidth').toInt(),
            el.get('data-modalImgHeight').toInt()
         );
      });
   });

   /**
    * Highlight the table of contents link whose heading is currently visible.
    */
   when($('sidebar-wiki-toc'), toc => {
      (function initialize() {
         if (toc.hasClass('js-dynamic-toc')) {
            generateToc();
         }

         // Animate the scrolling for the window.
         animateLinkedHashChanges(toc);

         // Highlight the first visible heading in the ToC
         const headings = $$('#mainBody [id^="Section_"]');
         let inView = [];

         const observer = new IntersectionObserver(
            entries => {
               entries.forEach(
                  entry =>
                     entry.isIntersecting // element crossed into view
                        ? inView.push(entry.target)
                        : (inView = inView.filter(h => h !== entry.target)) // element crossed out of view
               );
               inView.sort((a, b) => a.offsetTop - b.offsetTop);

               if (inView.length) {
                  onHeadingVisible(inView[0]);
               }
            },
            { threshold: 0 } // isIntersecting will be true as soon as any part of the element is in the viewport
         );
         headings.forEach(heading => observer.observe(heading));

         tableOfContentsGeneratedResolved(toc);
      })();

      function generateToc() {
         let ul = toc.getElement('ul');
         if (!ul) {
            ul = new Element('ul');
            toc.adopt(
               new Element('div', {
                  class: 'toc',
               }).adopt(ul)
            );
         }

         let detailsToc = ul.get('html');
         ul.empty();

         let lastH2 = null;
         let guideSection = null;
         let newSections = $$('.js-dynamic-toc-section, #Wiki_Details');
         // eslint-disable-next-line unicorn/no-for-loop
         for (let i = 0; i < newSections.length; i++) {
            let heading = newSections[i];
            if (heading.id == 'Wiki_Details') {
               if (detailsToc) {
                  ul.adopt(
                     new Element('li').adopt(
                        new Element('a', {
                           text: _js('Details'),
                           href: '#Wiki_Details',
                           class: 'toc-section',
                        }),
                        new Element('ul', {
                           html: detailsToc,
                        })
                     )
                  );
               }
               lastH2 = null;
               continue;
            }

            let firstId = heading.id.split(' ')[0];
            let li = new Element('li').adopt(
               new Element('a', {
                  // Text should match the section header title.
                  // i.e. "Section_Related_Pages" -> "Related Pages"
                  text: firstId.replace(/^Section_/, '').replace(/_/g, ' '),
                  href: '#' + heading.id,
                  class: heading.tagName === 'H3' ? 'toc-subsection' : 'toc-section',
               })
            );

            if (heading.tagName === 'H3' && lastH2 !== null) {
               lastH2.adopt(new Element('ul').adopt(li));
            } else {
               lastH2 = li;
               ul.adopt(li);
               if (heading.hasClass('js-guide-section')) {
                  guideSection = li;
               }
            }
         }

         // Because of how Augments are rendered, we set up a placeholder Guide
         // Section at the top of the page. If every guide augment fails to
         // render we should remove this section from the page and TOC.
         if (
            guideSection &&
            guideSection.getElements('ul').length === 0 &&
            $$('.highlight-guides').length === 0
         ) {
            guideSection.remove();
            $$('.js-guide-section')[0].remove();
         }
      }

      /**
       * This will add a smooth scrolling animation when clicking a link to
       * an anchor (e.g., <a href="#section-1">). It overrides the default browser
       * behavior that instantly jumps to the anchor.
       *
       * Affects all <a href="#..."> children of the provided Element.
       */
      function animateLinkedHashChanges(toc) {
         toc.addEvent('click:relay(a[href^=#])', ev => {
            // ev.target may be the <a> element or an element within the <a>
            let a = ev.target.tagName.toLowerCase() == 'a' ? ev.target : ev.target.getParent('a');

            let hash = a.get('href');
            let id = hash ? hash.slice(1) : null; // strip the leading #
            let el = id ? $(id) : null;

            if (!el) {
               return;
            }

            el.removeClass('emphasize-target');

            ev.preventDefault(); // We'll do the "default" with `pushState`.

            // Don't scroll past the bottom of the page or the animation will
            // abruptly get cut off.
            let targetY = Math.min(
               el.getPosition().y,
               document.body.clientHeight - window.innerHeight
            );

            // Don't scroll at all if we're already at the right position.
            if (Math.abs(targetY - window.pageYOffset) < 1) {
               // This class can be used to add emphasis to something that was
               // already in view.
               el.addClass('emphasize-target');
               return;
            }

            if (history.pushState) {
               // pushState won't make the browser scroll position jump, that way
               // we can animate it.
               history.pushState(null, null, hash);

               el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
               });
            } else {
               // Fallback for older browsers.
               window.location.hash = hash;
            }
         });
      }

      /**
       * When a heading is visible in the wiki body, highlight the ToC link
       * associated with it. Also, scroll the sidebar if necessary.
       */
      function onHeadingVisible(heading) {
         // Remove the "scrolled" class from all links and get the link
         // that corresponds to the visible heading.
         let scrolled = toc
            .getElements('a')
            .removeClass('scrolled')
            .filter('[href$="#' + heading.get('id') + '"]');

         // Apply a css class to the link and all of its parent links.
         if (scrolled.length) {
            let scrolledLink = scrolled[0];

            scrolledLink.getParents('li').getElement('> a').addClass('scrolled');
         }
      }
   });
});

// This is here to give the mobile TOC a signal that we are done generating
// the desktop TOC and thus it can parse the dom to make the mobile copy.
// eslint-disable-next-line no-var
export var tableOfContentsGenerated = (window.tableOfContentsGenerated = new Promise(resolve => {
   window.tableOfContentsGeneratedResolved = resolve;
}));
